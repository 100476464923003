import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useCart } from "../../context/CartContext";
import { isLogin } from "../../utils/functions";

const AddOrRemoveCartItem = ({
  id,
  name,
  image,
  item_price,
  partner_id,
  total_allowed_quantity = 5,
  minimum_order_quantity = 1,
  addons_id,
  variantId,
  currency,
  setIsVisible,
  addons,
  short_description,
  variants,
  indicator,
  rating,
  is_restro_open,
  product_qty,
  justifyContent = "end"
}) => {
  const [quantity, setQuantity] = useState(1);

  const { addToCart, store_data, Remove_data, RemoveCartData } = useCart();

  useEffect(() => {
    if (Number(product_qty) > 0) {
      setQuantity(Number(product_qty));
    } else {
      setQuantity(Number(minimum_order_quantity));
    }
  }, [minimum_order_quantity, product_qty]);

  // Add quantity to cart
  const addQuantityToCart = (quantity) => {
    manage_cart(variantId, quantity, addons_id, name, item_price, image, partner_id);
  };

  // Increment quantity
  const handleIncrement = () => {
    if (Number(quantity) < Number(total_allowed_quantity)) {
      const newQuantity = Number(quantity) + 1;
      setQuantity(newQuantity);
      addQuantityToCart(newQuantity);
    }
  };

  // Decrement quantity
  const handleDecrement = (id) => {
    if (Number(quantity) > Number(minimum_order_quantity)) {
      const newQuantity = Number(quantity) - 1;
      setQuantity(newQuantity);
      addQuantityToCart(newQuantity);
    } else {
      if (Number(quantity) === 1) {
        Remove_data(id);
        RemoveCartData(id);
        if (setIsVisible) {
          setIsVisible(false);
        }
      }
    }
  };

  //   API
  const manage_cart = (
    variantId_data,
    quantity_data,
    addonsId_data,
    title_data,
    item_price_data,
    image_data,
    partner_id_data
  ) => {
    // eslint-disable-next-line
    {
      isLogin()
        ? addToCart(variantId_data, quantity_data, addonsId_data)
        : store_data(
          variantId_data,
          quantity_data,
          addonsId_data,
          title_data,
          item_price_data,
          image_data,
          partner_id_data,
          minimum_order_quantity,
          total_allowed_quantity,
          short_description,
          indicator,
          addons,
          variants,
          rating,
          is_restro_open
        );
      // eslint-disable-next-line
    }
  };


  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: {justifyContent} }}>
      <RemoveIcon
        className={quantity > 1 ? "minus_quantity2" : "minus_quantity"}
        onClick={() => handleDecrement(id)}
      />
      <Typography variant="body1" component="span" sx={{ mx: 2 }}>
        {quantity}
      </Typography>
      <AddIcon className="plus_quantity" onClick={() => handleIncrement()} />
    </Box>
  );
};

export default AddOrRemoveCartItem;

import React, { useCallback, useEffect, useState } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    CircularProgress,
} from "@mui/material";
import config from "../../utils/config";
import { useTranslation } from "react-i18next";
import CustomLocationSearch from "./CustomLocationSearch";
import { extractAddress } from "../../utils/functions";

const libraries = ["places"];
const mapContainerStyle = { width: "100%", height: "400px" };
const defaultCenter = {
    lat: Number(config.latitude) || 32.7766642,
    lng: Number(config.longitude) || -96.79698789999999,
};
const defaultCity = "Dallas, GA, USA";
const defaultLocation = {
    formatted_address: ""
}
const LocationAddressModal = ({ open, defaultValue = defaultLocation, onClose, googleMapsApiKey, onSelectLocation }) => {
    const { t } = useTranslation();
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey,
        libraries,
    });

    const [selectedLocation, setSelectedLocation] = useState(defaultCenter);
    const [marker, setMarker] = useState(null);
    const [mapCenter, setMapCenter] = useState(defaultCenter);
    const [searchAddress, setSearchAddress] = useState(defaultLocation.formatted_address || defaultCity);

    const handleLocationSelect = useCallback((location) => {
        setSelectedLocation(location);
        setMarker({ lat: location.lat, lng: location.lng });
        setMapCenter({ lat: location.lat, lng: location.lng });
        setSearchAddress(location.formatted_address || defaultCity);
    }, []);

    const updateLocationDetails = () => {
        if (selectedLocation) {
            onSelectLocation(selectedLocation);
            onClose();
        }
    };

    const handleMarkerDragEnd = useCallback(async (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        setMarker({ lat: lat, lng: lng });

        // Fetch updated address
        const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?key=${googleMapsApiKey}&latlng=${lat},${lng}`;
        await fetch(geocodeUrl)
            .then((response) => response.json())
            .then((location) => {
                const addressDetails = location.results[0];
                console.log(addressDetails);
                const address = extractAddress(addressDetails);
                const formatted_address = addressDetails.formatted_address;
                setSearchAddress(formatted_address); // Update the search input
                setSelectedLocation({
                    lat: lat,
                    lng: lng,
                    formatted_address: formatted_address,
                    ...address
                });
            });
    }, [googleMapsApiKey]);

    useEffect(() => {
        if (open && defaultValue && defaultValue.lat) {
            defaultValue.lat = Number(defaultValue.lat);
            defaultValue.lng = Number(defaultValue.lng);
            handleLocationSelect(defaultValue);
        }
    }, [defaultValue, open]);

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <CircularProgress />;

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogContent>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <CustomLocationSearch
                        value={searchAddress}
                        defaultValue={searchAddress}
                        onLocationSelect={handleLocationSelect} />

                    <GoogleMap mapContainerStyle={mapContainerStyle} center={mapCenter} zoom={15}>
                        {marker && (
                            <Marker
                                draggable={true}
                                onDragEnd={handleMarkerDragEnd}
                                position={marker}
                            />
                        )}
                    </GoogleMap>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={updateLocationDetails} color="primary" variant="contained">
                    {t("select_location")}
                </Button>
                <Button onClick={onClose} color="secondary" variant="outlined">
                    {t("close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default LocationAddressModal;

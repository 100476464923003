import React, { useCallback, useEffect, useRef, useState } from "react";
import config from "../../utils/config";
import { Divider, IconButton, InputBase, Modal, Paper } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import { toast } from "react-hot-toast";
import * as api from "../../utils/api";
import StarRateIcon from "@mui/icons-material/StarRate";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import useOutsideClick from "./useOutsideClick";
import { Link } from "react-router-dom";
import { useSearch } from "../../context/SearchContext";
import { lat_long } from "../../utils/functions";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";
import LocationAddressModal from "../cart/LocationAddressModal";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useSelector } from "react-redux";
import DeliveryLocationsListModal from "../cart/DeliveryLocationsListModal";

const apiKey = config.YOUR_GOOGLE_MAPS_API_KEY;
const geocodeJson = "https://maps.googleapis.com/maps/api/geocode/json";
const configObj = config;

export const removeCountry = (address) => {
  return address.replace(", USA", "");
};

function SearchBarNew() {

  const cities = useSelector((state) => state.Cities)?.cities || [];

  const searchInput = useRef(null);
  const [show, setSate] = useState(false);
  const ref = useRef();
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const isMediumScreen = useMediaQuery("(max-width:1024px)");

  const width = isSmallScreen ? "650px" : isMediumScreen ? "465px" : "550px";

  const [filteredData, setFilteredData] = useState([]);

  const {
    setLoading,
    city_deliverable,
    get_restaurants,
    get_products,
    get_categories,
  } = useSearch();
  const { t } = useTranslation();

  useOutsideClick(ref, () => {
    if (show) setSate(false);
  });

  const [searchValue, setSearchValue] = useState("");
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);

  const handleSearch = (e) => {
    setSearchValue(e.target.value.trim());
  };

  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const handleLocationModalOpen = () => setLocationModalOpen(true);
  const handleLocationModalClose = () => setLocationModalOpen(false);
  const [location, setLocation] = useState({});

  const city_id = localStorage.getItem("city");
  const selected_city = localStorage.getItem("selected_city");
  const current_city = localStorage.getItem("current_city");
  const showSearchBar = false; // temporarily hide this item

  const handleSelectLocation = (selectedLocation) => {
    const selectedCity = selectedLocation.city;
    console.log(selectedLocation);
    setLocation(selectedLocation);

    lat_long("latitude", selectedLocation.lat);
    lat_long("longitude", selectedLocation.lng);

    setLoading(false);
    localStorage.setItem("city", selectedCity); // need to check again
    localStorage.setItem("selected_city", selectedCity);
    localStorage.removeItem("current_city");
    localStorage.setItem("selectedLocation", JSON.stringify(selectedLocation));

    city_deliverable(selectedCity).then(() => {
      get_restaurants(selectedCity);
      get_products(selectedCity);
      get_categories();
    });

  };

  const getProducts = useCallback(async (city_id) => {
    const response = await api.get_products(
      "",
      "",
      "",
      searchValue,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      city_id,
      ""
    );
    if (response.error) {
      setFilteredData([]);
    } else {
      setFilteredData(response.data);
    }
  }, [searchValue]);

  const updateCity = useCallback(async () => {
    const selectedLocation = JSON.parse(localStorage.getItem("selectedLocation") || '{}');
    let city = localStorage.getItem("city");;
    if (selectedLocation && selectedLocation['city']) {
      setLocation(selectedLocation);
      city = selectedLocation['city'];
    }
    const city_id = city; // localStorage.getItem("city");
    await getProducts(city_id);
  }, [getProducts]);

  useEffect(() => {
    if (cities.length > 0) {
      console.log('cities: ', cities);
      updateCity();
      // handleLocationModalOpen(true);
    }
    const timerId = setTimeout(async () => {
      if (searchValue === "") {
        setFilteredData([]); // Clear results if search is empty
        return;
      }

      try {
        await updateCity();
        console.log('cities: ', cities);
      } catch (error) {
        toast.error(error.message);
      }
    }, 1000); // Adjust delay as needed

    return () => clearTimeout(timerId); // Cleanup timer on component unmount or re-render
  }, [searchValue, cities, updateCity]); // Trigger effect when searchValue changes

  return (
    <>

      <DeliveryLocationsListModal
        googleMapsApiKey={configObj.googleMapKey}
        open={locationModalOpen}
        defaultValue={location}
        onClose={handleLocationModalClose}
        modalId={'location-address'}
        modalCls={'location-address-cls'}
        onSelectLocation={handleSelectLocation}
      />

      

      {showSearchBar && <div className="desktop-search-bar">
        <Paper
          ref={ref}
          component="form"
          className="searchbar-form desktop-header"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: { width },
            margin: "auto",
          }}
        >
          <div className="searchBar-sec">
            <div>

              {!(location && location.city) &&
                <IconButton sx={{ p: "10px" }} aria-label="search"
                  onClick={() => handleLocationModalOpen(true)}>
                  <KeyboardArrowDownIcon />
                </IconButton>
              }

            </div>
          </div>

          {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
          <IconButton sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
          <div className="input_dropdown">
            <InputBase
              sx={{ flex: 1 }}
              placeholder={t("search_bar_text")}
              inputProps={{ "aria-label": "search google maps" }}
              onChange={(e) => handleSearch(e)}
              onClick={() => setSate(!show)}
            />

            <div className="dropdown_Sec">
              {show && (
                <>
                  {city_id != null ? (
                    <>
                      {filteredData.length > 0 ? (
                        <>
                          {filteredData &&
                            filteredData.map((value, index) => {
                              return (
                                <div className={`dropdown_data`} key={index}>
                                  {value.partner_details.map(
                                    (partner_details, index) => (
                                      <Link
                                        to={`/store/${partner_details.slug}`}
                                        style={{ width: "100%" }}
                                      >
                                        <div className="list_Data" key={index}>
                                          <div className="partner_image">
                                            <img
                                              className="partner_profile"
                                              src={
                                                partner_details.partner_profile
                                              }
                                              alt="logo"
                                            />
                                          </div>
                                          <div className="partner_detail">
                                            <p className="partner_name">
                                              {partner_details.partner_name}
                                            </p>
                                            <div className="rating_and_time">
                                              <p className="staricon">
                                                <StarRateIcon />
                                                {partner_details.partner_rating}
                                              </p>
                                              <p className="cooktime">
                                                <AccessTimeIcon />
                                                {
                                                  partner_details.partner_cook_time
                                                }
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </Link>
                                    )
                                  )}
                                </div>
                              );
                            })}
                        </>
                      ) : (
                        <>
                          <div className={`dropdown_data`}>
                            <h5>{t("find_your_nearest_restaurants_here")}</h5>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div className={`dropdown_data`}>
                        <h5>{t("sorry_no_result_found")}</h5>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </Paper >
      </div>}

      {/* <LocationAddressModal
        googleMapsApiKey={configObj.googleMapKey}
        open={locationModalOpen}
        defaultValue={location}
        onClose={handleLocationModalClose}
        modalId={'location-address'}
        modalCls={'location-address-cls'}
        onSelectLocation={handleSelectLocation}
      /> */}

    </>
  );
}

export default SearchBarNew;

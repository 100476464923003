import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useCart } from ".././context/CartContext";

const OrderPlaced = () => {
  const location = useLocation();
  const [orderId, setOrderId] = useState("");

  const {
    clearCart, // eslint-disable-next-line
  } = useCart();
  useEffect(() => {
    let orderId = localStorage.getItem("order_id");
    if (!orderId) {
      orderId = location.state.orderId;
    }
    setOrderId(orderId);
    const timer = setTimeout(() => {
      clearCart();
    }, 1000);

    // Clean up the timer to avoid memory leaks
    return () => clearTimeout(timer);
  }, []);


  return (
    <>
      {orderId && (<div className="confirmed-order">
        <div className="confirmed-img">
          <img
            src={process.env.PUBLIC_URL + "/images/confirmed.jpg"}
            alt="confirmed"
          />
        </div>
        <Typography variant="h5" component="h5" sx={{ fontWeight: "bold" }}>
          <span className="highlight">Congratulations</span> Your order placed
          successfully!
        </Typography>
        <Typography variant="h5" component="h5" sx={{ fontWeight: "bold" }}>
          Your order number is <span className="highlight">{orderId}</span>
        </Typography>
        <Link to="/">
          <Button
            variant="outlined"
            color="error"
            sx={{ textAlign: "center" }}
            className="mt20"
          >
            Home
          </Button>
        </Link>
        <Link to="/orders">
          <Button
            variant="outlined"
            color="error"
            sx={{ textAlign: "center", marginLeft: "20px" }}
            className="mt20"
          >
            Orders
          </Button>
        </Link>

      </div>)}
    </>
  );
};

export default OrderPlaced;

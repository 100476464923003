import { Button, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as api from "../../../utils/api";
import { useAuth } from "../../../context/AuthContext";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useProfile } from "../../../context/ProfileContext";
import "../../../assets/css/login.css";

const SignUp = ({ profile, setProfile, handleLoginClose, setUserId }) => {
    const demoMode = process.env.REACT_APP_DEMO_MODE;

    const [phoneWithoutCountry, setPhoneWithoutCountry] = useState(
        demoMode === "true" ? "8888888889" : ""
    );

    const { t } = useTranslation();
    const { setUserDetails } = useAuth();
    const { setUserInfo } = useProfile();

    const formSubmit = async (e) => {
        e.preventDefault();
        const { username, email, country_code, fcm_id } = profile;

        api
            .register_user(
                username,
                email,
                phoneWithoutCountry,
                country_code,
                "",
                fcm_id
            )
            .then((response) => {
                if (response.error) {
                    toast.error(response.message);
                } else {
                    setProfile(response.data);
                    const userData = { ...response.data, token: response.token };
                    setUserDetails(userData);
                    setUserId(response.uid);
                    toast.success("Successfully Registered");
                    localStorage.setItem("user", JSON.stringify(userData));
                    setUserInfo(response.data);
                    handleLoginClose();
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                }
            })
            .catch((error) => {
                toast.error(error.message);
            });
    };

    const handleProfileUpdate = (field, value) => {
        setProfile((prev) => ({ ...prev, [field]: value }));
    };

    useEffect(() => {
        if (profile && profile.mobile) {
            setPhoneWithoutCountry(profile.mobile);
        }
    }, [profile]);

    return (
        <form className="login-form" onSubmit={formSubmit}>
            <label htmlFor="number" className="signup-label">
                {t("Signup")}
            </label>

            <TextField
                fullWidth
                required
                id="fullName"
                label="Username"
                name="username"
                color="error"
                className="mb20"
                placeholder={"Enter Your Name"}
                defaultValue={profile?.username}
                onChange={(e) => handleProfileUpdate(e.target.name, e.target.value)}
            />

            <TextField
                fullWidth
                required
                id="emailid"
                label="email"
                name="email"
                color="error"
                className="mb20"
                placeholder={"Enter your email"}
                onChange={(e) => handleProfileUpdate(e.target.name, e.target.value)}
            />

            <TextField
                fullWidth
                required
                value={phoneWithoutCountry} // Controlled value
                id="mobileid"
                label="mobile"
                color="error"
                className="mb20"
                placeholder={"Enter your Mobile no"}
                onChange={(e) => setPhoneWithoutCountry(e.target.value)} // Update state directly
            />

            <Button
                variant="contained"
                type="submit"
                className="send-button"
                fullWidth
            >
                {t("register")}
            </Button>
        </form>
    );
};

export default SignUp;

let config = {
  // default country set
  DefaultCountrySelectedInMobile: "us",
  // default language
  DefaultLanguage: "en",
  Demo: process.env.REACT_APP_DEMO_MODE,
  //google Places key
  DefaultCity: process.env.REACT_APP_DEMO_CITY,
  YOUR_GOOGLE_MAPS_API_KEY: process.env.REACT_APP_MAP_API_KEY,
  latitude: process.env.REACT_APP_DEMO_LATITUDE,
  longitude: process.env.REACT_APP_DEMO_LONGITUDE,
  demoMOde: process.env.REACT_APP_DEMO_MODE,
  googleMapKey: process.env.REACT_APP_MAP_API_KEY
};

export default config;

import React, { useCallback, useEffect, useState } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    CircularProgress,
    Autocomplete,
    TextField,
} from "@mui/material";
import config from "../../utils/config";
import { useTranslation } from "react-i18next";
import CustomLocationSearch from "./CustomLocationSearch";
import { extractAddress } from "../../utils/functions";

const libraries = ["places"];
const mapContainerStyle = { width: "100%", height: "400px" };
const defaultCenter = {
    lat: Number(config.latitude) || 32.7766642,
    lng: Number(config.longitude) || -96.79698789999999,
};
const defaultCity = "Dallas, GA, USA";
const defaultLocation = {
    formatted_address: ""
}

const cities = [
    {
        ...{ lat: 32.7766642, lng: -96.79698789999999 },
        city: 'Dallas',
        formatted_address: "Dallas, TX, USA",
        latitude: 32.7766642,
        longitude: -96.79698789999999,
        pincode: 75068,
        state: 'Texas',
        stateCode: 'TX',
        country: 'USA'
    },
    {
        ...{ lat: 33.174679, lng: -96.8092489 },
        city: 'Frisco',
        formatted_address: "Eldorado Parkway, Frisco, TX",
        latitude: 33.174679,
        longitude: -96.8092489,
        pincode: 75068,
        state: 'Texas',
        stateCode: 'TX',
        country: 'USA'
    },
    {
        ...{ lat: 32.9807466, lng: -96.9148316 },
        city: 'Carrollton',
        formatted_address: "Carrollton, TX",
        latitude: 33.174679,
        longitude: -96.8092489,
        pincode: 75006,
        state: 'Texas',
        stateCode: 'TX',
        country: 'USA'
    },
    {
        ...{ lat: 33.1366984, lng: -96.8024346 },
        city: 'Frisco',
        formatted_address: "Preston Rd, Frisco, TX",
        latitude: 33.174679,
        longitude: -96.8092489,
        pincode: 75034,
        state: 'Texas',
        stateCode: 'TX',
        country: 'USA'
    },
    {
        ...{ lat: 32.8984103, lng: -96.9642176 },
        city: 'Irving',
        formatted_address: "Irving, TX",
        latitude: 33.174679,
        longitude: -96.8092489,
        pincode: 75039,
        state: 'Texas',
        stateCode: 'TX',
        country: 'USA'
    },
    {

        ...{ lat: 33.0630559, lng: -96.7993915 },
        city: 'Plano',
        formatted_address: "Plano, TX",
        latitude: 33.174679,
        longitude: -96.8092489,
        pincode: 75024,
        state: 'Texas',
        stateCode: 'TX',
        country: 'USA'
    },
    {

        ...{ lat: 33.174388, lng: -96.6823502 },
        city: 'McKinney',
        formatted_address: "Mckinney, TX",
        latitude: 33.174679,
        longitude: -96.8092489,
        pincode: 75070,
        state: 'Texas',
        stateCode: 'TX',
        country: 'USA'
    },
    {

        ...{ lat: 33.15535509999999, lng: -96.89125759999999 },
        city: 'Frisco',
        formatted_address: "Little Elm, TX",
        latitude: 33.174679,
        longitude: -96.8092489,
        pincode: 75033,
        state: 'Texas',
        stateCode: 'TX',
        country: 'USA'
    },
];

const DeliveryLocationsListModal = ({ open = true, defaultValue, onClose, googleMapsApiKey, onSelectLocation }) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey,
        libraries,
    });

    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(defaultCenter);
    const [selectedCity, setSelectedCity] = useState(defaultCenter);
    const [value, setValue] = useState();

    const updateLocation = (newValue) => {
        console.log(newValue);
        setValue(newValue);
        setSelectedLocation(newValue);
    };

    const handleChange = (newInputValue) => {
        console.log(newInputValue);
        setSelectedCity(newInputValue);
    };

    const updateLocationDetails = () => {
        if (selectedLocation) {
            onSelectLocation(selectedLocation);
            onClose();
        }
    };

    useEffect(() => {
        console.log('defaultValue', defaultValue);
        if (!(defaultValue && defaultValue?.['formatted_address'])) {
            // setIsOpen(true);
        } else {
            setIsOpen(open);
            if (defaultValue && defaultValue.formatted_address) {
                setValue(defaultValue);
                setSelectedCity(defaultValue.formatted_address);
            }
        }
    }, [defaultValue, open, setIsOpen]);

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <CircularProgress />;
    
    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogContent sx={{ height: 150, paddingTop: "50px" }}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    {/* {selectedCity} <br />
                    {JSON.stringify(value)} <br /> */}
                    <Autocomplete
                        value={value}
                        onChange={(event, newValue) => {
                            updateLocation(newValue);
                        }}
                        inputValue={selectedCity}
                        onInputChange={(event, newInputValue) => {
                            handleChange(newInputValue);
                        }}
                        id="country-select-demo"
                        sx={{ width: 300 }}
                        options={cities}
                        autoHighlight
                        getOptionLabel={(option) => option.formatted_address}
                        renderOption={(props, option) => {
                            const { key, ...optionProps } = props;
                            return (
                                <Box
                                    key={key}
                                    component="li"
                                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                    {...optionProps}
                                >
                                    {option.formatted_address}
                                </Box>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select Location"
                                slotProps={{
                                    htmlInput: {
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    },
                                }}
                            />
                        )}
                    />

                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={updateLocationDetails} color="primary" variant="contained">
                    {t("change_location")}
                </Button>
                <Button onClick={onClose} color="secondary" variant="outlined">
                    {t("close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeliveryLocationsListModal;

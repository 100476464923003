import { Avatar, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Logout from "@mui/icons-material/Logout";
import Swal from "sweetalert2";
import { defaultColor } from "../../../utils/functions";
import "../../../assets/css/login.css";
import "../../../assets/css/logout.css";
import { useAuth } from "../../../context/AuthContext";
import { useProfile } from "../../../context/ProfileContext";

const MySwal = withReactContent(Swal);

const Profile = ({ profile }) => {
    const { t } = useTranslation();
    //handle signout
    const { signOut } = useAuth();
    const { setUserInfo } = useProfile();
    const [anchorEl, setAnchorEl] = useState(null);

    const navigate = useNavigate();

    //signout
    const handleSignout = () => {
        MySwal.fire({
            title: '',
            html: `
           
            <img 
              src="${process.env.PUBLIC_URL}/images/logut.png"
              alt="Logout" 
              style="width: 160px; margin: 10px auto 10px;" 
            />
            <h3 style="font-size: 1.125rem; font-weight: 600 !important; color: #333;">Are you sure you want to log out?</h3>
            <p style="font-size: 0.85rem; margin: 10px 0; color: #333;">You will be signed out of your account.</p>
          `,
            showCancelButton: true,
            confirmButtonText: "Log out",
            cancelButtonText: "Cancel",
            reverseButtons: true,
            buttonsStyling: false,
            customClass: {
                popup: "custom-swal-popup",
                confirmButton: "logout-confirm-btn",
                cancelButton: "logout-cancel-btn",
            },
        }).then((result) => {
            if (result.isConfirmed) {
                // Handle sign out logic
                signOut();
                setUserInfo("");
                navigate("/");
                window.location.reload();
            }
        });
    };

    const open = Boolean(anchorEl);
    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Tooltip title={t("account_settings")}>
                <IconButton
                    onClick={handleClickMenu}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                >
                    <Avatar sx={{ width: 32, height: 32 }} src="/broken-image.jpg" />
                </IconButton>
            </Tooltip>
            <Typography
                variant="h6"
                component="h6"
                sx={{ margin: "auto", cursor: "pointer" }}
            ></Typography>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <Link to="/account">
                    <MenuItem>{t("my_profile")}</MenuItem>
                </Link>
                <Divider />
                <MenuItem onClick={handleSignout}>
                    <ListItemIcon>
                        <Logout fontSize="small" sx={{ color: defaultColor }} />
                    </ListItemIcon>
                    <Typography
                        variant="inherit"
                        sx={{ color: defaultColor, fontWeight: 500, marginLeft: -1 }}
                    >
                        {t("log_out")}
                    </Typography>
                </MenuItem>
            </Menu>
        </>
    );
};

export default Profile;

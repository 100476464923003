import { createSelector, createSlice } from "@reduxjs/toolkit";

let initialState = {
  cities: [],
};
const CitySlice = createSlice({
  name: "cities",
  initialState,
  reducers: {
    setCities: (state, action) => {
      const payload = action.payload;
      state.cities = payload && payload.length > 0 ?
        payload.sort((a, b) => (a === "Dallas" ? -1 : b === "Dallas" ? 1 : 0)) : [];
      // payload.filter(city => city.name !== 'Chicago') : [];
      return state;
    },
  },
});

export const { setCities } = CitySlice.actions;
export default CitySlice.reducer;

import { Avatar, Grid, Typography, Button, Modal, Box, IconButton, Divider } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectData } from "../store/reducers/settings";
import * as api from "../utils/api";
import { ModalClose, ModalDialog } from "@mui/joy";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { formatDateTime, toFixedDecimal } from "../utils/functions";
import "../assets/css/orders.css";

const OrderDetails = ({ order }) => {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open, setOpen] = React.useState(false);
  const data = useSelector(selectData);
  const currency = data.currency;
  const orders = order;
  const { t } = useTranslation();

  return (
    <>
      <Button
        variant="contained"
        color="success"
        sx={{
          marginTop: 1,
          textTransform: "capitalize",
          fontWeight: "600",
        }}
        onClick={handleOpen}
      >
        {t("order_details")}
      </Button>

      <Modal
        open={open}
        onClose={(e) => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ maxWidth: "100%" }}
      >
        {orders &&
          (<Box className="order-container">
            {/* Header */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0.5rem 1rem",
                borderBottom: "1px solid #ddd",
              }}
            >
              <Typography variant="h6" className="modal-heading">
                Order Summary
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>

            {/* Order Info */}
            <Box sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0.5rem",
              borderBottom: "1px solid #ddd"
            }}>
              <Typography variant="subtitle1" sx={{ fontWeight: "600", marginBottom: "8px" }}>
                Order # : {orders['s_order_id']}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Order Placed: {formatDateTime(orders.date_added)}
              </Typography>
            </Box>

            {/* Items List */}
            {orders.order_items.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0.5rem 1rem",
                  borderBottom: index < orders.order_items.length - 1 ? "1px solid #ddd" : "none",
                }}
              >
                <Box
                  component="img"
                  src={item.image}
                  alt={item.product_name}
                  sx={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "8px",
                    objectFit: "cover",
                    marginRight: "1rem",
                  }}
                />
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="body1" className="heading-bold">
                    {item.product_name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {item.quantity} × {currency}{toFixedDecimal(item.price)}
                  </Typography>
                </Box>
                <Typography variant="body1" className="heading-bold">
                  {currency}{toFixedDecimal(item.sub_total)}
                </Typography>
              </Box>
            ))}

            {/* Order Totals */}
            <Box sx={{ padding: "0 1rem" }}>
              <Divider sx={{ marginBottom: "1rem" }} />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="body2" color="textSecondary">
                    Total
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  <Typography variant="body2" className="heading-bold">
                    {currency}{toFixedDecimal(orders.total)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" color="textSecondary">
                    {t("taxes_and_charges")}
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  <Typography variant="body2" className="heading-bold">
                    {currency}{toFixedDecimal(orders.tax_amount)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" color="textSecondary">
                    {t("total_pay")}
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  <Typography variant="body2" className="heading-bold">
                    {currency}{toFixedDecimal(orders.total_payable)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" className="heading-bold">
                    Grand Total
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "right", }}>
                  <Typography variant="body2" className="heading-bold">
                    {currency}{toFixedDecimal(orders.total_payable)}
                  </Typography>
                </Grid>
                <Divider sx={{ marginY: "1rem" }} />
              </Grid>

            </Box>
            {/* Partner address */}
            {/* <Box sx={{ padding: "0.5rem 1rem", backgroundColor: "#f9f9f9" }}>
            <Typography variant="body2" color="textSecondary">
              Deliver To
            </Typography>
            <Typography variant="body1" className="heading-bold">
              {orders.order_items[0].partner_details[0].partner_name}
              {orders.order_items[0].partner_details[0].partner_address}
            </Typography>
          </Box> */}
            {/* Order Notes */}
            {orders.notes && (<Box sx={{ padding: "0.5rem 1rem", backgroundColor: "#ffffff" }}>
              <Typography variant="body2" color="textSecondary">
                Note:
              </Typography>
              <Typography variant="body1" className="heading-bold">
                {orders.notes}
              </Typography>
            </Box>)}
            {/* Delivery Address */}
            <Box sx={{ padding: "0.5rem 1rem", backgroundColor: "#f9f9f9" }}>
              <Typography variant="body2" color="textSecondary">
                Deliver To
              </Typography>
              <Typography variant="body1" className="heading-bold">
                {orders.address}
              </Typography>
            </Box>
          </Box>)}
      </Modal>
    </>
  );
};

export default OrderDetails;

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Grid, Typography } from "@mui/material";

const FooterSocialIcons = ({ data, copyright }) => {
    return (

        <div className="footer-bottom">
            <Grid container spacing={2}>
                <Grid item md={6}>
                    <div className="footer-bottom-Typography">
                        <Typography
                            color="#fff"
                            variant="subtitle1"
                            component="div"
                        >
                            <Typography
                                className="highlight"
                                variant="subtitle1"
                                component="span"
                                color="#fff !important"
                                dangerouslySetInnerHTML={{
                                    __html: data && copyright,
                                }}
                            />
                        </Typography>
                    </div>
                </Grid>
                <Grid item md={6} justify="end">
                    <div className="social-icons">
                        <a
                            href={data.web_settings[0].facebook_link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FacebookIcon />
                        </a>
                        <a
                            href={data.web_settings[0].instagram_link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <InstagramIcon />
                        </a>

                        <a
                            href={data.web_settings[0].youtube_link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <YouTubeIcon />
                        </a>
                        <a
                            href={data.web_settings[0].twitter_link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <TwitterIcon />
                        </a>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default FooterSocialIcons;
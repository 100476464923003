import React from "react";
import {
    Modal,
    Box,
    Typography,
    IconButton,
    Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useCustomModal } from "../../context/CustomModalContext";
import { useNavigate } from "react-router";
import { secondaryColor } from "../../utils/functions";

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 400,
    backgroundColor: "#fff",
    borderRadius: "12px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
    padding: "24px",
    textAlign: "center",
};

const OrderConfirmModal = () => {
    const { isOpen, modalData, closeModal } = useCustomModal();
    const navigate = useNavigate();

    return (
        <Modal
            open={isOpen}
            onClose={closeModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={modalStyle}>
                {/* Close Button */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        position: "relative",
                    }}
                >
                    <IconButton onClick={closeModal} sx={{ position: "absolute", right: 0 }}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                {/* Modal Content */}
                <Box sx={{ marginTop: 2, marginBottom: 4 }}>
                    <img
                        src={process.env.PUBLIC_URL + "/images/OrderConfirmation.png"}
                        alt="Order Success"
                        style={{ width: "150px", marginBottom: "1rem" }}
                    />
                    <Typography
                        id="modal-title"
                        variant="h5"
                        sx={{ fontSize: "1.2rem", fontWeight: "600", marginBottom: 1 }}
                    >
                        Congratulations!
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: 1 }}>
                        Your order is placed Successfully.
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{ fontWeight: "600", marginBottom: 1 }}
                    >
                        Order ID : #{modalData.orderId}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "gray" }}>
                        Date of Delivery : {modalData.deliveryDate}
                    </Typography>
                </Box>

                {/* Buttons */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 2,
                        marginTop: 2,
                    }}
                >
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                            // alert("Navigate to Home");
                            navigate("/");
                            closeModal();
                        }}
                        sx={{ textTransform: "capitalize" }}
                    >
                        Home
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            backgroundColor: secondaryColor,
                            textTransform: "capitalize",
                            color: "#000"
                        }}
                        onClick={() => {
                            // alert("Navigate to My Orders");
                            navigate("/orders");
                            closeModal();
                        }}
                    >
                        My Orders
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default OrderConfirmModal;

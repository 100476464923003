import { useTranslation } from "react-i18next";
import Breadcrumbs from "../components/breadcrumbs/";
import Layout from "../components/layouts/Layout";
import { Container, Typography } from "@mui/material";
import { isLogin } from "../utils/functions";
import OrderListing from "./OrderListing";

function Orders() {

    const { t } = useTranslation();

    return (
        <Layout title={t("orders")}>
            <Breadcrumbs title={t("orders")} crumb={t("orders")} />
            <Container>
                {
                    isLogin() ?
                        (
                            <div className="orders-list">
                                <OrderListing />
                            </div>
                        ) :

                        (<div className="no_login">
                            <img
                                src={process.env.PUBLIC_URL + "/images/Mobile-login.jpg"}
                                alt="login"
                            />
                            <Typography
                                variant="h5"
                                component="h5"
                                sx={{ textAlign: "center" }}
                            >
                                {t("please")} <span className="highlight">{t("login")}.</span>
                            </Typography>
                        </div>)
                }

            </Container>
        </Layout>
    )
}

export default Orders;
import { useState } from "react";
import { Button, Stack, TextField } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import "../../../assets/css/login.css";

import { selectData } from "../../../store/reducers/settings";
import { useCart } from "../../../context/CartContext";
import firebaseconfig from "../../../utils/firebase";
import config from "../../../utils/config";
import * as api from "../../../utils/api";
import { useAuth } from "../../../context/AuthContext";
import { useProfile } from "../../../context/ProfileContext";
import { validatePhoneNumber } from "../input-validations/input-validations";

const demoMOde = process.env.REACT_APP_DEMO_MODE;
const SignIn = ({ setProfile, setNewUserScreen, handleLoginClose }) => {
    const [confirmResult, setConfirmResult] = useState("");
    const [verificationCode, setVerificationCode] = useState(
        demoMOde === "true" ? "123456" : ""
    );
    const [phoneWithoutCountry, setPhoneWithoutCountry] = useState(
        demoMOde === "true" ? "8888888889" : ""
    );
    const [userId, setUserIdSignIn] = useState("");
    const [isSend, setIsSend] = useState(false);
    const [load, setLoad] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(
        demoMOde === "true" ? "918888888889" : ""
    );
    const [CartData, setCartData] = useState([]);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { setUserInfo } = useProfile();
    const { setUserDetails } = useAuth();
    const { get_cart } = useCart();
    const authentication_mode = useSelector(selectData)?.authentication_mode;
    let firebase = firebaseconfig();

    const navigateToCart = () => {
        setTimeout(() => {
            navigate("/cart");
            window.location.reload();
            // navigate("/", { replace: true });
        }, 100);
    };

    const updatUserId = (userId) => {
        setUserIdSignIn(userId);
    };

    const updateNewUserScreen = (isNewScreen) => {
        setNewUserScreen(isNewScreen);
    };

    //sign with number
    const onSubmit = (e) => {
        e.preventDefault();
        setLoad(true);
        if (authentication_mode === 1) {
            let phone_number = "+" + phoneNumber;
            if (validatePhoneNumber(phone_number)) {
                api
                    .verify_user(phoneWithoutCountry)
                    .then((response) => {
                        setIsSend(true);
                        setLoad(false);
                        setConfirmResult(response);
                        toast.success("OTP has been sent");
                    })
                    .catch((error) => {
                        window.recaptchaVerifier.render().then(function (widgetId) {
                            window.recaptchaVerifier.reset(widgetId);
                        });
                        toast.error(error.message);
                        setLoad(false);
                    });
            } else {
                setLoad(false);
                toast.error("Please Enter correct Mobile Number with Country Code");
            }
        } else {
            let phone_number = "+" + phoneNumber;
            if (validatePhoneNumber(phone_number)) {
                const appVerifier = window.recaptchaVerifier;

                firebase.auth
                    .signInWithPhoneNumber(phone_number, appVerifier)
                    .then((response) => {
                        setIsSend(true);
                        setLoad(false);
                        setConfirmResult(response);
                    })
                    .catch((error) => {
                        window.recaptchaVerifier.render().then(function (widgetId) {
                            window.recaptchaVerifier.reset(widgetId);
                        });
                        toast.error(error.message);
                        setLoad(false);
                    });
            } else {
                setLoad(false);
                toast.error("Please Enter correct Mobile Number with Country Code");
            }
        }
    };

    //verify code
    const handleVerifyCode = (e) => {
        e.preventDefault();
        setLoad(true);
        if (authentication_mode === 1) {
            api
                .verify_otp({ phoneWithoutCountry, verificationCode })
                .then((response) => {
                    setLoad(false);
                    setProfile(response?.user);
                    if (!response.error) {
                        api.userAuth(parseInt(phoneWithoutCountry), "").then((res) => {
                            if (res.data.length === 0) {
                                toast.error(res.message);
                                updateNewUserScreen(true);
                            } else {
                                let userData = res.data;
                                userData = { ...userData, token: res.token };
                                setUserDetails(userData);
                                updatUserId(res.uid);
                                setUserInfo(userData);
                                console.log(res.data.id);

                                const cart_data = localStorage.getItem("cart");
                                const cart = JSON.parse(cart_data);
                                console.log(cart);
                                console.log(res.data.id);
                                console.log("----- New User Authentication ----");
                                if (cart !== null) {
                                    api
                                        .add_to_cart_data(
                                            res.data.id,
                                            false,
                                            cart
                                        )
                                        .then((response) => {
                                            if (!response.error) {
                                                setCartData(response.data);
                                                localStorage.removeItem("cart");
                                                get_cart();
                                            }
                                        });
                                }

                                toast.success("Logged in Successfully");
                                handleLoginClose();

                                setTimeout(function () {
                                    navigateToCart();
                                }, 1000); // 1000 milliseconds = 1 seconds
                            }
                        });
                    } else {
                        toast.error(response.message);
                    }
                    // .then((response) => {
                    //   window.location.reload();
                    // });
                });
        } else {
            confirmResult
                .confirm(verificationCode)
                .then((response) => {
                    setLoad(false);
                    setProfile(response.user);
                    if (response.additionalUserInfo.isNewUser) {
                        updateNewUserScreen(true);
                    } else {
                        api
                            .userAuth(parseInt(phoneWithoutCountry), "")
                            .then((res) => {
                                if (res.error) {
                                    toast.error(res.message);
                                } else {
                                    let userData = res.data;
                                    userData = { ...userData, token: res.token };
                                    setUserDetails(userData);
                                    updatUserId(res.uid);
                                    setUserInfo(userData);
                                    console.log(res.data.id);

                                    const cart_data = localStorage.getItem("cart");
                                    const cart = JSON.parse(cart_data);
                                    console.log(cart);
                                    console.log(res.data.id);
                                    console.log("----- Existing user authentication ----");
                                    if (cart && cart.length > 0) {
                                        api
                                            .add_to_cart_data(
                                                res.data.id,
                                                false,
                                                cart
                                            )
                                            .then((response) => {
                                                if (!response.error) {
                                                    console.log(response);
                                                    setCartData(response.data);
                                                    localStorage.removeItem("cart");
                                                    get_cart();
                                                }
                                            });
                                    }

                                    toast.success("Logged in Successfully");
                                    handleLoginClose();
                                }
                            })
                            .then((response) => {
                                navigateToCart();
                            });
                    }
                })
                .catch((error) => {
                    setLoad(false);
                    try {
                        window.recaptchaVerifier.render().then((widgetId) => {
                            try {
                                window.recaptchaVerifier.reset(widgetId);
                            } catch (error) {
                                console.log(error);
                            }
                        });
                    } catch (error) {
                        console.log(error);
                    }
                    toast.error(error.message);
                });
        }
    };

    //resend otp
    const resendOtp = (e) => {
        e.preventDefault();
        setLoad(true);
        if (authentication_mode === 1) {
            let phone_number = "+" + phoneNumber;
            if (validatePhoneNumber(phone_number)) {
                api
                    .resend_otp(phoneWithoutCountry)
                    .then((response) => {
                        setIsSend(true);
                        setLoad(false);
                        setConfirmResult(response);
                        toast.success("OTP has been sent");
                    })
                    .catch((error) => {
                        window.recaptchaVerifier.render().then(function (widgetId) {
                            window.recaptchaVerifier.reset(widgetId);
                        });
                        toast.error(error.message);
                        setLoad(false);
                    });
            } else {
                setLoad(false);
                toast.error("Please Enter correct Mobile Number with Country Code");
            }
        } else {
            let phone_number = "+" + phoneNumber;
            const appVerifier = window.recaptchaVerifier;
            firebase.auth
                .signInWithPhoneNumber(phone_number, appVerifier)
                .then((response) => {
                    setIsSend(true);
                    setLoad(false);
                    setConfirmResult(response);
                    toast.success("OTP has been sent");
                })
                .catch((error) => {
                    window.recaptchaVerifier.render().then(function (widgetId) {
                        window.recaptchaVerifier.reset(widgetId);
                    });
                    toast.error(error.message);
                    setLoad(false);
                });
        }
    };


    const handleKeyPress = (event) => {
        //console.log("Key pressed:", event.key);
        if (event.key === "Enter") {
            event.preventDefault();
            onSubmit(event);
        }
    };

    const onChangePhoneNumber = (e) => {
        e.preventDefault();
        setVerificationCode("");
        setConfirmResult(null);
        setIsSend(false);
    };

    return (
        <>
            {!isSend ? (
                <form
                    className="login-form"
                    onSubmit={onSubmit}
                >
                    <label htmlFor="number" className="login-label">
                        {t("please_enter_your_mobile_number")}
                    </label>

                    <PhoneInput
                        value={phoneNumber}
                        country={config.DefaultCountrySelectedInMobile}
                        countryCodeEditable={false}
                        onlyCountries={['us', 'in']}  // Restrict to India and USA
                        autoFocus={true}
                        onChange={(value, data) => {
                            setPhoneWithoutCountry(
                                value.slice(data.dialCode.length)
                            );
                            setPhoneNumber(value);
                        }}
                        onKeyDown={(event) => handleKeyPress(event)} // Add keypress event handler
                        className="mb-3 position-relative d-inline-block w-100 form-control mt20"
                    />

                    <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        className="send-button"
                    >
                        {!load ? t("request_otp") : t("please_wait")}
                    </Button>

                </form>
            ) : null}
            {isSend ? (
                <form
                    className="login-form"
                    onSubmit={handleVerifyCode}
                >
                    <TextField
                        color="error"
                        id="outlined-number"
                        fullWidth
                        label={t("enter_your_otp")}
                        type="text"
                        value={verificationCode}
                        onChange={(e) =>
                            setVerificationCode(e.target.value)
                        }
                        className="form-control"
                    />
                    <div className="text-end mt-2">
                        <div
                            className="resend-otp"
                            onClick={resendOtp}>
                            {t("resend_otp")}
                        </div>
                    </div>
                    <Stack
                        spacing={2}
                        direction="row"
                        sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 2,
                            mb: 2
                        }}
                    >
                        <Button
                            variant="contained"
                            type="submit"
                            className="submit-btn"
                        >
                            {!load ? t("submit") : t("please_wait")}
                        </Button>

                        <Button
                            variant="outlined"
                            onClick={onChangePhoneNumber}
                            color="error"
                        >
                            {t("back")}
                        </Button>

                    </Stack>

                </form>
            ) : null}
        </>
    );
};

export default SignIn;
